import React from "react"

import { useState} from "react"

import { Link} from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import { isActive} from "../utils/Utils"

import AnimatedHeading from "../components/AnimatedHeading"

import "../styles/navigationMenu.css"
import "../styles/navigationMenuMobile.css"

import logo from "./../../slike/logo.png"

const KnActiveLink = ( props ) => ( 
	<AniLink cover bg="linear-gradient(60deg, #29323c 0%, #485563 100%)" to={props.to} id={ props.id} getProps={isActive} {...props} />
)


export default function NavigationMenuMobile() {
	
	const [ mobileMenuOpen, setMobileMenuOpen] = useState( false)
	
	return (
		<>
			<button className="kn-navigation-toggle-button" role="button" aria-label="Izbornik" onClick={ () => { setMobileMenuOpen( !mobileMenuOpen)}}>
				<i className="fas fa-bars"></i>
			</button>
			
			<div className={`kn-main-menu-mobile-container ${mobileMenuOpen ? "kn-main-menu-mobile-open" : ""}`}>
				<ul className="kn-main-menu-mobile-top">
					<li>
						<Link to="/" className="kn-logo-link">
							<img src={ logo} className="kn-logo" alt="logo" width="auto" height="80" />
						</Link>
					</li>
					<li>
						<ul className="kn-info-header-list">
							<li>
								<AnimatedHeading className="fas fa-envelope" delay={350} delayBetween={35}><i aria-hidden="true"></i></AnimatedHeading>
								<AnimatedHeading className="kn-info-header-link" delay={450} delayBetween={35}><a href="mailto:mario@geokontura.hr">mario@geokontura.hr</a></AnimatedHeading>
							</li>
							<li>
								<AnimatedHeading className="fas fa-mobile-alt"  delay={450} delayBetween={35}><i aria-hidden="true"></i></AnimatedHeading>
								<AnimatedHeading className="kn-kontakt-link" delay={550} delayBetween={35}><a href="tel:+385 91 550 58 83">+385 91 550 58 83</a></AnimatedHeading>
							</li>
						</ul>
					</li>
				
					<li className="kn-navigation-toggle-item">
						<button className="kn-navigation-toggle-button-close" role="button" aria-label="zatvori izbornik" onClick={ () => { setMobileMenuOpen( !mobileMenuOpen)}}>
							<i className="fas fa-times"></i>
						</button>
					</li>
				</ul>
				
				<nav className="kn-mobile-nav">
					<div className="kn-mobile-nav-container">
						<KnActiveLink to="/">Početna</KnActiveLink>
						<Link to="#o-nama" onClick={ () => { setMobileMenuOpen( !mobileMenuOpen)}}>O nama</Link>
						<Link to="#usluge" onClick={ () => { setMobileMenuOpen( !mobileMenuOpen)}}>Usluge</Link>
						<Link to="#kontakt" onClick={ () => { setMobileMenuOpen( !mobileMenuOpen)}}>Kontakt</Link>
					</div>
				</nav>
			</div>
		</>
	)
}