import React from "react"

import { Link} from "gatsby"

import logo from "./../../slike/logo.png"

import "../styles/footer.css"

import NavigationMenu from "./NavigationMenu"

import footerBackground from "./../../slike/footer.jpg"

import koonaLogo from "./../../slike/logo_koona_white.png"

export default function Footer() {
  return (
	<footer className="kn-footer" style={{ backgroundImage:`url(${footerBackground})`,backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
		
		<div className="kn-footer-inner-container">
			<div className="kn-col-6 kn-first-column">
				
				<div className="kn-footer-logo-container">
					<Link to="/" className="kn-footer-logo-link"><img className="kn-footer-logo" src={logo} alt="logo"/></Link>
				</div>
				<div className="kn-footer-company-info">
					<h4 className="kn-company-director"><span>Vlasnik:</span> Mario Jović</h4>
					<h4 className="kn-company-address"><span>Adresa:</span> Biograd na moru, Dr. Franje Tuđmana 82</h4>
					<h4 className="kn-company-oib"><span>OIB:</span> 22232257038</h4>
					<h4 className="kn-company-mbs"><span>Temeljni kapital:</span> 20.000 kn</h4>
				</div>
			</div>
			
			<div className="kn-col-6 kn-second-column">
				<NavigationMenu isFooter={true}/>
			</div>
			
			<div className="kn-col-12 kn-third-column kn-footer-column-designed-by-koona">
				<p className="kn-designed-by-koona-text">Designed by </p>
				<Link to="https://koona.tech">
					<img className="kn-designed-by-koona-logo" src={ koonaLogo} alt="Koona Tech logo" width="auto" height="40" />
				</Link>						
			</div>

		</div>
	</footer>
	)
}
