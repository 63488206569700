import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import NavigationMenu from "./NavigationMenu"
import NavigationMenuMobile from "./NavigationMenuMobile"
import AnimatedHeading from "./AnimatedHeading"

import useWindowWidth from "../hooks/useWindowWidth"

import logo from "./../../slike/logo.png"

import "../styles/header.css"

export default function Header() {
	
	const windowWidth = useWindowWidth()

	
	return (
		<header className="kn-header">
			<div className="kn-header-inner">
				<div className="kn-logo-container">
					<AniLink cover bg="linear-gradient(60deg, #29323c 0%, #485563 100%)" to="/"><img src={logo} alt="logo"/></AniLink>
				</div>
				{ windowWidth > 1099 ?
					<ul className="kn-info-header-list">
						<li>
							<AnimatedHeading className="fas fa-envelope" delay={350} delayBetween={35}><i aria-hidden="true"></i></AnimatedHeading>
							<AnimatedHeading className="kn-info-header-link" delay={450} delayBetween={35}><a href="mailto:mario@geokontura.hr">mario@geokontura.hr</a></AnimatedHeading>
						</li>
						<li>
							<AnimatedHeading className="fas fa-mobile-alt"  delay={450} delayBetween={35}><i aria-hidden="true"></i></AnimatedHeading>
							<AnimatedHeading className="kn-kontakt-link" delay={550} delayBetween={35}><a href="tel:+385 91 550 58 83">+385 91 550 58 83</a></AnimatedHeading>
						</li>
					</ul>
					:
					<></>
				}
				<div className="kn-navigation-container">
					{ windowWidth > 1200 ? 
						<NavigationMenu />
						:
						<NavigationMenuMobile />
					}
				</div>
			</div>
		</header>
	)
}

